
































































































import { Vue, Component } from 'vue-property-decorator'

@Component<Obituary>({
  apollo: {
    obituaries: {
      query: require('~base/graphql/queries/FetchObituaries.gql'),
      prefetch: false,
    },
  },
})
export default class Obituary extends Vue {}
