




import { Component, Vue } from 'vue-property-decorator'
import Obituary from '~components/content/widgets/Obituary.vue'

@Component({
  components: {
    Obituary,
  },
})
export default class ObituariesWidget extends Vue {}
